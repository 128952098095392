
import { defineComponent, ref, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import type { Moment } from 'moment'
import { Modal } from "ant-design-vue"
import type { DeputyData, DelegationSettingData, ApprovalType } from '@/views/User/ApprovalProcess/ApprovalDelegation/type'
import * as API from "@/API/approvalDelegation";
import { message } from 'ant-design-vue';

enum EditMode {
  ADD,
  EDIT
}

export default defineComponent({
  setup() {
    const { state } = useStore()
    const deputies = ref<DeputyData[]>([])
    const approvalTypes = ref<ApprovalType[]>([])
    const formState = reactive<{
      delegationUserId?: string;
      approvalType?: ApprovalType;
      startDate?: Moment;
      endDate?: Moment;
      id?: number;
      defaultDelegation?: string;
      delegationUserName?: string;
    }>({
      startDate: moment(),
      endDate: moment()
    })

    const tableColumns = [
      {
        title: 'Deputy',
        dataIndex: 'delegationUserName'
      },
      {
        title: 'Approval Type',
        dataIndex: 'approvalType'
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate'
      },
      {
        title: 'End Date',
        dataIndex: 'endDate'
      },
      {
        title: 'Operator',
        slots: {customRender: 'operator'}
      },
    ]

    const tableData = ref<DelegationSettingData[]>([])
    const getTableData = () => {
      API.getDelegationList().then(data => {
        tableData.value = data
      })
    }

    const formRef = ref()
    const rules = {
      delegationUserId: [
        { required: true, message: 'Please select deputy', trigger: 'blur' }
      ],
      approvalType: [
        { required: true, message: 'Please select approval type', trigger: 'blur' }
      ],
      startDate: [
        { required: true, message: 'Please select start date', trigger: 'change', type: 'object' }
      ],
      endDate: [
        { required: true, message: 'Please select end date', trigger: 'change', type: 'object' }
      ]
    }

    const infoEditorDialogVisiable = ref(false)
    const editMode = ref<EditMode>(EditMode.ADD)

    const editorDialogTitle = computed(() => {
      return editMode.value === EditMode.ADD ? 'Add Delegation' : 'Edit Delegation'
    })

    const disabledStartDate = (date: Moment) => {
      return date.isBefore(moment(), 'day')
    }

    const disabledEndDate = (date: Moment) => {
      return formState.startDate && date.isBefore(formState.startDate, 'day')
    }

    const handleChangeStartDate = (date: Moment) => {
      if (formState.endDate && formState.endDate.isBefore(date, 'day')) {
        formState.endDate = date
      }
    }

    const getDeputies = () => {
      API.findByUsrnmWithoutLoginUser().then(data => {
        deputies.value = data.map((item: any) => {
          return {
            delegationUserId: item.usrid,
            delegationUserName: item.usrnm
          }
        })
      })
    }

    const handleToEdit = (mode: EditMode, record?: DelegationSettingData) => {
      formRef.value.clearValidate()
      switch (mode) {
        case EditMode.ADD:
          formState.delegationUserId = undefined
          formState.approvalType = undefined
          formState.startDate = moment()
          formState.endDate = moment()
          formState.id = undefined
          formState.defaultDelegation = '0'
          formState.delegationUserName = undefined

          getDeputies()
          break;
        case EditMode.EDIT:
          if (record) {
            formState.delegationUserId = record.delegationUserId
            formState.approvalType = record.approvalType
            formState.startDate = moment(record.startDate)
            formState.endDate = moment(record.endDate)
            formState.id = record.id
            formState.defaultDelegation = record.defaultDelegation
            formState.delegationUserName = record.delegationUserName

            if (record.defaultDelegation + '' === '1') {
              getDeputies()
            } else {
              deputies.value = [
                {
                  delegationUserId: record.delegationUserId,
                  delegationUserName: record.delegationUserName
                }
              ]
            }
          }
          break;
      }
      editMode.value = mode
      infoEditorDialogVisiable.value = true
    }

    const handleConfirm = () => {
      formRef.value
        .validate().then(() => {
          const params = {
            approvalType: formState.approvalType,
            defaultDelegation: formState.defaultDelegation,
            delegationUserId: formState.delegationUserId,
            delegationUserName: formState.delegationUserName,
            endDate: formState.endDate?.format('YYYY/MM/DD') + ' 23:59:59',
            id: formState.id,
            startDate: formState.startDate?.format('YYYY/MM/DD') + ' 00:00:00'
          }
          API.saveDelegationSetting(params).then(res => {
            if (res.code === '0') {
              message.success('Successfully Save!')
              infoEditorDialogVisiable.value = false
              getTableData()
            // } else {
            //   message.warning(res.message)
            }
          })
        }).catch(() => undefined)
    }

    const handleDelete = (record: DelegationSettingData) => {
      Modal.confirm({
        content: 'Do you confirm to delete current file?',
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk() {
          const params = {
            id: record.id
          }
          API.deleteDelegation(params).then(res => {
            if (res.code === '0') {
              message.success('Successfully Delete!')
              getTableData()
            // } else {
            //   message.warning(res.message)
            }
          })
        }
      })
    }

    const deputyChange = (val: string) => {
      if (val && deputies.value && deputies.value.length > 0) {
        const index = deputies.value.findIndex(item => item.delegationUserId === (val + ''))
        formState.delegationUserName = deputies.value[index].delegationUserName
      }
    }

    approvalTypes.value = [
      'All',
      'Program Approval',
      'Payout Approval'
    ]

    const init = () => {
      getTableData()
    }

    init()

    return {
      deputies,
      approvalTypes,
      formRef,
      formState,
      rules,
      tableColumns,
      tableData,
      infoEditorDialogVisiable,
      editMode,
      EditMode,
      editorDialogTitle,
      disabledStartDate,
      disabledEndDate,
      handleChangeStartDate,
      handleToEdit,
      handleConfirm,
      handleDelete,
      deputyChange
    }
  }
})
